import React, { useEffect, useState } from "react"
import { Typography } from "@material-ui/core"
import MessageError from "../components/messages/messageError"
import { Grid } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Paper, TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import api from "../services/api";


const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'end',
    },

    gridItem: {
        margin: 8,
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}))

export default function CadastroOrdem() {
    const classes = useStyles()
    const [messageError, setMessageError] = useState('')
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [cadastroOrdem, setCadastroOrdem] = useState([]);


    useEffect(() => {
        api.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);
            }).catch(err => {
                if (err.message === "Network Error") {
                    setMessageError(err.message)
                }
                // console.log(err)
            })
    }, [setProvincias])


    // useEffect(() => {
    //     api.post('/conselho/createselhos')
    //         .then(res => {
    //             setOrdens(res.data.ordens);
    //             // console.log(ordens)
    //         }).catch(err => {
    //             // console.log(err)
    //             if (err.message === "Network Error") {
    //                 setMessageError(err.message)
    //             }
    //         })
    // }, [])

    function HandleChange(e) {

        e.target.files ?
            setCadastroOrdem({ ...cadastroOrdem, [e.target.name]: e.target.files[0] })
            :
            setCadastroOrdem({ ...cadastroOrdem, [e.target.name]: e.target.value })

        e.target.name === 'provinciaId' &&
            api.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                }).catch(err => console.log(err))

    }

    return (
        <div >
            {messageError && <MessageError message={messageError} />}

            <Typography align="center" style={{ color: 'red', fontSize: 25, fontFamily: 'Work Sans', marginTop: 20, fontWeight: 'bold' }} variant="h1">
                CADASTRO ORDEM
            </Typography>

            <Grid container className={classes.root}>
                <Grid xs={12} md={2} item className={classes.gridItem}>
                    <Paper >
                        <label for="foto" style={{ cursor: 'pointer', textAlign: 'center' }}>
                            {cadastroOrdem?.foto ?
                                <>
                                    <Typography variant='body2'>
                                        <img src={URL.createObjectURL(cadastroOrdem.foto)} alt="Imagem" width="100%" title="alterar foto.." />
                                    </Typography>
                                </>
                                :
                                <Typography align="center" variant='body2' style={{ padding: 15 }}>
                                    <Avatar />
                                    Logotipo
                                </Typography>}
                            <input accept="image/png, image/jpg, image/jpeg" type="file" name="foto" id="foto" style={{ display: 'none' }} onChange={HandleChange} />
                        </label>
                    </Paper>
                </Grid>

                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="text"
                        required
                        label="Sigla"
                        fullWidth
                        size="small"
                        name="sigla"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={cadastroOrdem?.sigla}
                    />
                </Grid>
                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="text"
                        required
                        label="Designação"
                        fullWidth
                        size="small"
                        name="designacao"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={cadastroOrdem?.designacao}
                    />
                </Grid>
            </Grid>

            <Grid container className={classes.root}>
                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="number"
                        required
                        label="Contacto"
                        fullWidth
                        size="small"
                        name="telefone"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={cadastroOrdem?.telefone}
                    />
                </Grid>

                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="email"
                        required
                        label="Email"
                        fullWidth
                        size="small"
                        name="email"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={cadastroOrdem?.email}
                    />
                </Grid>
                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="url"
                        required
                        label="URL"
                        fullWidth
                        size="small"
                        name="url"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={cadastroOrdem?.url}
                    />
                </Grid>

                <Grid container className={classes.root}>
                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Provincia"
                            select
                            fullWidth
                            size="small"
                            name="provinciaId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroOrdem?.provinciaId || ''}
                        >
                            {provincias?.map((provincia) => (
                                <MenuItem key={provincia._id} value={provincia._id}>
                                    {provincia.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        {cadastroOrdem?.provinciaId ?
                            <TextField
                                required
                                type="text"
                                label="Municipio"
                                select
                                fullWidth
                                size="small"
                                name="municipioId"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={cadastroOrdem?.municipioId || ''}
                            >
                                {municipios?.map((municipio) => (
                                    <MenuItem key={municipio._id} value={municipio._id}>
                                        {municipio.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                            :
                            <TextField
                                type="text"
                                disabled
                                label="Municipio"
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                        }
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Morada (bairro, rua)"
                            fullWidth
                            size="small"
                            name="bairro"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroOrdem?.bairro}
                        />
                    </Grid>
                </Grid>
            </Grid >
        </div >
    )
}