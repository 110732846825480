import React from "react";
import { makeStyles } from '@material-ui/core/styles';
// import { Typography } from "@mui/material";


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        marginTop: 20,
        height: '85vh',
        // background: 'url(/img/capa/AnuncioCNP-Engenheiros.jpg)',
        background: 'url(/img/capa/AnuncioCNP-EngenheirosAndroide.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        backgroundPosition: 'justify',
        // padding: theme.spacing(0),
        // backgroundAttachment: 'fixed',
        // position: 'relative',


    },
    conteudo: {
        marginLeft: '50%',
        padding: 20,
        color: '#fff',

        [theme.breakpoints.down('xs')]: {
            // marginLeft: '0',
            // color: '#fff0f0',
            marginLeft: 20,
        }
    },
    DownloadCNPApp: {
        width: 170,
        margin: 3,
        borderRadius: 8,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
}))

export default function AnuncioApp() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/* <div className={classes.conteudo}>
                <Typography style={{ marginTop: 150, fontSize: 35, fontWeight: 'bold', fontFamily: 'Work Sans' }} variant="h1">
                    A SUA ORDEM <br />
                    PODEM ESTAR AQUÍ!
                </Typography>
                <Typography style={{ marginTop: 50, fontSize: 25, fontWeight: 'bold', fontFamily: 'Work Sans' }} variant="h1">
                    Uma plataforma, varias Ordens
                </Typography>
                <Typography style={{ marginTop: 20, fontSize: 20, fontFamily: 'Work Sans' }} variant="h1">
                    Uma plataforma que aloja as ordens , cria uma base de dados legítima que é gerida
                    remotamente pelas Ordens, e os membros têm acesso nível global.
                </Typography>

            </div> */}


            {/* <a href="" style={{ position: 'absolute', bottom: 50, right: 240 }}> <img src="img/AppStore.png" className={classes.DownloadCNPApp} /></a> */}
            {/* <a href="" style={{ position: 'absolute', bottom: 50, right: 50 }}> <img src="img/Androidapp.png" className={classes.DownloadCNPApp} /></a> */}
        </div>
    )
}