
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import api from '../../services/api';
import Loading from '../load/loading';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 230,
        margin: 15,
        border: '1px solid #a62929',
        borderRadius: 8,
        transition: '.4s',
        '&:hover': { background: '#b1b7ea', },
        [theme.breakpoints.down('xs')]: {
            width: '75%',
            margin: 5,
        },
    },
    cards: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },

}))

export default function Anunciados({refresh}) {
    const classes = useStyles();

    const [anuncios, setAnuncios] = useState('')

    useEffect(() => {
        setAnuncios('')

        // api.get('anuncios?anuncianteId=66bcb7441abc92c597f18c6d')
        // api.get('anuncios?anuncianteId=6527e7a0170638338bf088d9')
        api.get('anuncios')
            .then(res => {
                // console.log(res.data)
                setAnuncios(res.data.anuncios);

            }).catch(err => {
                // console.log(err)
            })
    }, [refresh])

    return (
        anuncios ?
            <>
                <Typography align="center" style={{ color: 'red', fontSize: 25, fontFamily: 'Work Sans', marginTop: 20, fontWeight: 'bold' }} variant="h1">
                    ANUNCIADOS
                </Typography>
                

                <div className={classes.cards}>
                    {anuncios.map((anuncio) => (
                        anuncio.status === 'Activo' ?

                            <div key={anuncio._id} className={classes.root} title={anuncio.nome} >

                                <a href={anuncio.URL} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                                    <CardActionArea >
                                        <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                                            <CardMedia
                                                style={{ width: '100%', borderRadius: 8 }}
                                                component="img"
                                                alt="Logoanuncio"
                                                // height="150"
                                                //image={logo}
                                                image={anuncio.imagens[0]}
                                                title={anuncio.nome}
                                            />
                                        </div>
                                        <CardContent style={{ padding: 10 }}>
                                            <Typography color="textPrimary" noWrap variant="subtitle1" >
                                                {
                                                    anuncio.nome === 'anuncio dos Advogados de Angola' ?
                                                        'Conselho Provincial de Luanda'
                                                        :
                                                        anuncio.nome
                                                } <br />
                                            </Typography>

                                            <Typography color="textSecondary" gutterBottom variant="body1" >
                                                <strong> Endereço: </strong>
                                                {anuncio.local}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </a>
                            </div>

                            :

                            <div key={anuncio._id} className={classes.root} >
                            {/* <div key={anuncio._id} className={classes.root} style={{ background: '#fff', opacity: .2 }}> */}

                                <a href={anuncio.URL} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                                    <CardActionArea >
                                        <div style={{ display: 'flex', justifyContent: 'center', padding: 10, }}>
                                            <CardMedia
                                                style={{ width: '100%', borderRadius: 8 }}
                                                component="img"
                                                alt="Logoanuncio"
                                                // height="150"
                                                image={anuncio.imagens[0]}
                                            />
                                        </div>
                                        <CardContent style={{ padding: 10 }}>
                                            <Typography color="textPrimary" noWrap variant="subtitle1" >
                                                {anuncio.titulo}
                                            </Typography>
                                            <br />

                                            <Typography color="textSecondary" gutterBottom variant="body1" >
                                                {anuncio.descricao}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </a>
                            </div>
                    ))}
                </div>
            </>
            :
            <Loading />
    );
}
