import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { LinearProgress } from '@mui/material';
import { makeStyles } from "@material-ui/core";
import RedesSocias from "./redesSocias";
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles({
  menu: {
    textDecoration: 'none',
    fontSize: 18,
    color: '#000',
    paddingLeft: 2,
    paddingRight: 2,

    display: 'flex',
    marginLeft: 'auto',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',

    transition: '.3s',
    '&:hover': {
      color: '#b1b7ea',
      borderRadius: 3,
    },
  },

  appBar: {
    boxShadow: 'none',
    background: '#fff',
  },

  redesSocias: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: 'auto',
    // marginRight: 'auto'
  },

  menuItens: {
    marginRight: 20,
    marginLeft: 20,
    textDecoration: 'none',
    color: '#606467',
    cursor: 'pointer',
    transition: '.2s',
    '&:hover': {
      color: '#e43834',
      // borderBottom: '1px solid #484848',
      // color: '#b1b7ea',
    },
  },
})

export default function Header({ load }) {
  const navigate = useNavigate()
  const classes = useStyles()

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" color="inherit" className={classes.appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <Box >
            <img src="/img/logo2.svg" alt="logoCNP" width={120} />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', justifyContent: 'end' } }} >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="error"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem>
                {/* <Typography variant="subtitle2" ><Link to="/" className={classes.menuItens}> INÍCIO</Link></Typography> */}
                <Typography id='ordens' variant="subtitle2" ><a href="/#top" className={classes.menuItens}>INÍCIO</a></Typography>
              </MenuItem>
              <MenuItem >
                <Typography variant="subtitle2" ><a href="/#ordens" className={classes.menuItens}>ORDENS</a></Typography>
              </MenuItem>
              <MenuItem >
              <Typography variant="subtitle2" onClick={() => navigate('/sobre-cnp')}><span className={classes.menuItens}>SOBRE</span></Typography>
              </MenuItem>
              <MenuItem >
                <Typography variant="subtitle2" ><a href="/#contactos" className={classes.menuItens}>CONTACTO</a></Typography>
              </MenuItem>
              <MenuItem >
                <div style={{ display: 'flex', alignItems: 'center' }} className={classes.redesSocias}>
                  <RedesSocias />
                </div>
              </MenuItem>

            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <div className={classes.menu}>
              {/* <Typography variant="subtitle2" ><Link to="/" className={classes.menuItens}> INÍCIO</Link></Typography> */}
              <Typography variant="subtitle2" ><a href="/#top" className={classes.menuItens}>INÍCIO</a></Typography>
              <Typography variant="subtitle2" ><a href="/#ordens" className={classes.menuItens}>ORDENS</a></Typography>
              <Typography variant="subtitle2" onClick={() => navigate('/sobre-cnp')}><span className={classes.menuItens}>SOBRE</span></Typography>
              <Typography variant="subtitle2" ><a href="/#contactos" className={classes.menuItens}>CONTACTO</a></Typography>
            </div>

          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <div style={{ display: 'flex', alignItems: 'center' }} className={classes.redesSocias}>
              <RedesSocias />
            </div>
          </Box>
        </Toolbar>
      </Container>

      {
        load &&
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      }
    </AppBar >
  );
}
