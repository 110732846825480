
import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useState } from 'react'
import api from '../services/api';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {/* {'Copyright © '} */}
            {/* {new Date().getFullYear()} */}
            {' © '}
            CNP  2018 - 2023
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    img: {
        maxHeight: 55,
        width: 160,
        margin: 10,
    },
}));

export default function Login() {
    const classes = useStyles();
    const [login, setLogin] = useState([])

    function HandleChange(e) {
        setLogin({ ...login, [e.target.name]: e.target.value })

    }

    const submit = (e) => {
        e.preventDefault()
        entrar(login)
    }

    function entrar() {

        api.post('/auth/login-ordem-admin', login)
            .then(res => {
                // console.log(res)

            }).catch(err => {
                // console.log(err)
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Link to="/">
                    <img src="img/IconColor-CNP.svg" alt="CNP" className={classes.img} />
                </Link>
                {/* <Typography component="h1" variant="h5">
        Entrar
        </Typography> */}
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={HandleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={HandleChange}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        disabled
                        color='secondary'
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={submit}                    >
                        Entrar
                    </Button>

                    <Link href="#" variant="body2">
                        Esqueçeu a senha?
                    </Link>

                </form>
            </div>
            <Box mt={8}>
                <br />
                <br />
                <Copyright />
            </Box>
        </Container>
    );
}