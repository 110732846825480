
import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { useSelector } from 'react-redux';

export default function GoBack({ text }) {
    // const inscricao = useSelector(state => state.inscricao.dadosInscricao)
    const navigate = useNavigate()

    return (
        <Button size="mall" color="info" onClick={() => navigate(-1)}><ChevronLeftIcon />
            {text ? text : 'voltar'}
        </Button>
    )
}