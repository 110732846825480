import React, { useState } from "react";
import Footer from "../components/layout/Footer";
import NavBar from "../components/layout/NavBar";
import Capa from "../components/capa";
// import Anuncios from "../components/anuncios/Anuncios";
import Body from "../components/layout/body";
import { useEffect } from "react";
import ViewSearchModal from "../components/cnpBusca/ViewBuscaModal";
import MessageError from "../components/messages/messageError";
import Pesquisa from "../components/cnpBusca/buscador";
import api from "../services/api";
import ConsultorPorQrCode from "../components/cnpBusca/consultorPorQrCode";


export default function Home() {
    const [pesquisa, setPesquisa] = useState('')
    const [pesquisaError, setPesquisaError] = useState('')
    const [messageError, setMessageError] = useState('')
    const [load, setLoad] = useState(false)
    const [valor, setValor] = useState('')
    const [ordem, setOrdem] = useState('');
    const [ordens, setOrdens] = useState([])
    const [LoadingOrdens, setLoadingOrdens] = useState(false)

    useEffect(() => {
        api.get('ordems')
            .then(res => {
                setOrdens(res.data.ordens);
                setLoadingOrdens(true)
                // console.log(ordens)
            }).catch(err => {
                // console.log(err)
                if (err.message === "Network Error") {
                    setMessageError(err.message)
                }
            })
    }, [])

    return (
        <div>
            {messageError && <MessageError message={messageError} />}

            <NavBar load={load} />
            <Capa />

            {(pesquisa || pesquisaError) &&
                <ViewSearchModal
                    pesquisa={pesquisa}
                    pesquisaError={pesquisaError}
                    open={true}
                    ordem={ordem}
                    valor={valor}
                />
            }

            <ConsultorPorQrCode
                pesquisa={pesquisa}
                setPesquisa={setPesquisa}
                pesquisaError={pesquisaError}
                setPesquisaError={setPesquisaError}
                setLoad={setLoad}
            />

            <Pesquisa
                pesquisa={pesquisa}
                setPesquisa={setPesquisa}
                pesquisaError={pesquisaError}
                setPesquisaError={setPesquisaError}
                setLoad={setLoad}
                ordens={ordens}
                ordem={ordem}
                setOrdem={setOrdem}
                valor={valor}
                setValor={setValor}
            />

            <Body ordens={ordens} LoadingOrdens={LoadingOrdens} />
            <Footer />
        </div>
    )
}