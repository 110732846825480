
import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '15%',
        minWidth: 180,
        height: 150,
        margin: 20,
        // border: '1px solid #A50722',
        border: '1px solid #000',
        borderRadius: 10,
        transition: '1s',
        '&:hover': {
            background: '#b1b7ea',
            // width: 160,
            // height: 190,
        },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    }
}))

export default function Servicos({ icone, servicoNome, link }) {
    const classes = useStyles()

    return (
        <CardActionArea className={classes.root}>
            <Link style={{ textDecoration: 'none' }} to={link}>
                <CardContent style={{ paddingBottom: 0 }}>
                    <img src={icone} alt={servicoNome} width={50} />
                </CardContent>
                <CardContent >
                    <Typography  color="textPrimary" variant="subtitle1" style={{ lineHeight: 1, fontFamily: 'Work Sans', fontSize: 18 }}>
                        {servicoNome}
                    </Typography>
                </CardContent>
            </Link>
        </CardActionArea>
    )
}