import React from "react";
import Footer from "../components/layout/Footer";
import NavBar from "../components/layout/NavBar";
// import { useEffect } from "react";
import { Container, Divider, Typography } from "@mui/material";

export default function PoliticasServicos() {
    // const [pesquisa, setPesquisa] = useState('')

    // useEffect(() => {
    //         api.get('ordens')
    //             .then(res => {
    //                 setOrdens(res.data.ordens);
    //                 setLoadingOrdens(true)
    //                 // console.log(ordens)
    //             }).catch(err => {
    //                 // console.log(err)
    //                 if (err.message === "Network Error") {
    //                     setMessageError(err.message)
    //                 }
    //             })
    //     }, [])

    return (
        <>
            <NavBar />
            <Container style={{ minHeight: '90vh', maxWidth: 800, marginTop: 100, marginBottom: 50 }}>
                <Typography align="center" style={{ marginBottom: 50, fontSize: '2rem', fontFamily: 'Work Sans' }} variant="h1">
                    {/* Politicas e Servicos */}
                    <strong>   Política de privacidade</strong>
                    <Divider />
                </Typography>

                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    Pelo presente instrumento a Carteira Nacional Profissional divulga a sua Política de Privacidade e os seus Termos de Uso, assegurando a integridade e a segurança das informações pessoais identificáveis de seus usuários e colaboradores.
                </Typography>
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    O usuário pode navegar pela Plataforma sem a necessidade de fornecer as suas informações pessoais, mas o seu acesso implica na concordância com as regras de uso e de privacidade fixadas no presente termo.
                </Typography>
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    Para os serviços restritos, no entanto, poderá haver a requisição de dados do usuário. Neste caso, a Carteira Nacional Profissional assegura o armazenamento das informações de maneira sigilosa, não as compartilhando com terceiros sem prévia autorização, exceto quando solicitado por autoridade judicial.
                </Typography>
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    Os conteúdos de terceiros ou as notícias produzidas pelos veículos de comunicação, reproduzidos na Plataforma CNP, são publicados mediante a autorização expressa dos seus envolvidos. As informações contidas neste site são protegidas pela Lei de Direitos Autorais, por isso, é expressamente vedada à sua utilização para o envio, distribuição, publicação ou divulgação sem a autorização da Carteira Nacional Profissional. A reprodução indevida sujeita o infrator às penalidades previstas em lei.
                </Typography>
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    Com a aceitação do presente termo, o usuário compromete-se a utilizar adequadamente as informações disponibilizadas, sujeitando-se à legislação vigente.
                </Typography>
            </Container>
            <Footer />
        </>
    )
}
