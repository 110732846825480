import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Divider, Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
    img: {
        borderRadius: 20,
        padding: 5,
        transition: '1s',
        // '&:hover': {
        //     opacity: 0
        // },
    },
})

export default function AnuncioInformativoProfissoes() {
    const classes = useStyles();
    const [imageActive, setImageActive] = useState(false)
    const [image, setImage] = useState([
        'img/anuncios/cnp-infermeira.jpg',
        'img/anuncios/cnp-advogado.jpg',
        'img/anuncios/cnp-engCivil.jpg',
        'img/anuncios/cnp-arquitecto.jpg',
        'img/anuncios/cnp-engPetroleo.jpg',
    ])

    const HandleImage = (image) => {
        setImageActive(true)
        setImage(image)
    }

    return (
        <Container style={{ marginTop: 50 }}>
            <Typography align="center" style={{ color: 'red', fontSize: 32, fontFamily: 'Work Sans', marginTop: 30, fontWeight: 'bold' }} variant="h1">
                ANÚNCIOS
            </Typography>
            <Divider />
            <br />

            <Grid container className={classes.container}>
                <Grid xs={12} md item m >
                    {/* <div data-anime="left" >  */}
                    {
                        imageActive ?
                            <img src={image} alt="..." width={'100%'} className={classes.img} />
                            :
                            <img src={image[Math.floor(Math.random() * (4 - 0)) + 0]} alt="..." width={'100%'} className={classes.img} />
                    }
                    {/* </div> */}
                </Grid>

                <Grid xs={12} md item >
                    <Grid xs={12} md item m>
                        <img onMouseOver={() => HandleImage('img/anuncios/cnp-advogado.jpg')} src="img/anuncios/cnp-advogado.jpg" alt="..." width={'50%'} className={classes.img} />
                        <img onMouseOver={() => HandleImage('img/anuncios/cnp-engCivil.jpg')} src="img/anuncios/cnp-engCivil.jpg" alt="..." width={'50%'} className={classes.img} />
                    </Grid>
                    <Grid xs={12} md item m >
                        <img onMouseOver={() => HandleImage('img/anuncios/cnp-arquitecto.jpg')} src="img/anuncios/cnp-arquitecto.jpg" alt="..." width={'50%'} className={classes.img} />
                        <img onMouseOver={() => HandleImage('img/anuncios/cnp-engPetroleo.jpg')} src="img/anuncios/cnp-engPetroleo.jpg" alt="..." width={'50%'} className={classes.img} />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}