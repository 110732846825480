import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading({ text }) {
    return (
        <div align='center' style={{ marginTop: 50 }}>
            <CircularProgress disableShrink />
            {
                text &&
                <p style={{ fontFamily: 'Work Sans' }} >{text}</p>
            }
        </div>

    )
}