
import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Avatar } from '@material-ui/core';
// import StatusMembros from "../status/StatusMembros";

const useStyles = makeStyles((theme) => ({
    hover: {
        cursor: 'pointer',
        '&:hover': {
            // backgroundColor: '#fcdd06'
            backgroundColor: '#d2d6ff'
        },
    }
}))

export default function ListaBusca(props) {
    const classes = useStyles()

    return (
        <TableContainer component={Paper} style={{ padding: 10 }}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow >
                        <TableCell ><Typography style={{ fontFamily: 'Work Sans' }} noWrap> <strong>Foto</strong></Typography></TableCell>
                        <TableCell ><Typography style={{ fontFamily: 'Work Sans' }} noWrap> <strong>Nome</strong></Typography></TableCell>
                        {/* <TableCell align="center" style={{ fontFamily: 'Work Sans' }}><strong>Status</strong></TableCell> */}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.membros.map((membro, index) => (
                        <TableRow key={membro.dadosPessoais._id} className={classes.hover} onClick={() => { props.setIndex(index); props.setgetOfLista(true) }}>
                            <TableCell align="center" component="th" scope="row">
                                <Avatar size="lg" src={membro.dadosPessoais.fotoURL} />
                            </TableCell>
                            <TableCell  ><Typography style={{ fontFamily: 'Work Sans' }} noWrap>{membro?.nome}</Typography></TableCell>
                            {/* <TableCell align="center" style={{ fontFamily: 'Work Sans' }}>
                                <StatusMembros status={membro.status} />
                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}