import React from "react";
import { makeStyles } from '@material-ui/core/styles';
// import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        marginTop: 55,
        width: '100%',
        height: '70vh',
        // background: 'url(/img/capa/AnuncioCNP2-Engenheiros.jpg)',
        backgroundImage: 'url(/img/capa/capa2.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        [theme.breakpoints.down('xs')]: {
            height: '80vh',
            width: '100%',
            // background: 'url(/img/capa/AnuncioCNP2-EngenheirosPequeno.jpg)',
            background: 'url(/img/capa/capaMobile3.jpg)',
            backgroundSize: 'cover',
            // backgroundPosition: 'center',
        },
    },
    conteudo: {
        marginLeft: '40%',
        padding: 20,
        color: '#fff',

        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
    },
    titlePrincipal: {
        fontSize: '3.2rem',
        fontWeight: 'bold',
        fontFamily: 'Work Sans',

        [theme.breakpoints.down('xs')]: {
            marginTop: 10,
        },
    }
}))

export default function Capa() {
    const classes = useStyles();
    // const images = ['url(/img/capa/CPLCapaGrande.jpeg)', 'url(/img/capa/AnuncioCNP2-Engenheiros.jpg)']

    // const HandleSlid = () => {
    //     var view = document.getElementById('slid');
    //     view.style = "background: none"
    // }

    return (
        <div id="slid" className={classes.root} >
            <div className={classes.conteudo} > 
                {/* <Typography style={{ marginTop: 80, fontSize: '3.2rem', fontWeight: 'bold', fontFamily: 'Work Sans' }} variant="h1">
                    A SUA ORDEM
                    PODE ESTAR AQUI!
                </Typography>
                <Typography style={{ marginTop: 50, fontSize: '2.2rem', fontWeight: 'bold', fontFamily: 'Work Sans' }} variant="h2">
                    Uma plataforma, varias Ordens
                </Typography>
                <Typography style={{ marginTop: 10, fontSize: '1.2rem', fontFamily: 'Work Sans' }} variant="h3">
                    Uma plataforma que aloja as ordens , cria uma base de dados legítima que é gerida
                    remotamente pelas Ordens, e os membros têm acesso nível global.
                </Typography> */}
            </div>

            {/* <a className="animate__animated animate__flash animate__delay-2s" style={{ color: '#d02027', position: 'absolute', top: 15, left: 15 }} href={'https://cpl.cnp.ao/actualizacao-de-dados/associados'} target={'_blank'} rel="noreferrer" >
                Actualizar agora.
            </a> */}
        </div>
    )
}