import React from "react";
import Footer from "../components/layout/Footer";
import NavBar from "../components/layout/NavBar";
import { Container, Divider, Typography } from "@mui/material";

export default function Sobre() {

    return (
        <>
            <NavBar />
            <Container style={{ minHeight: '90vh', maxWidth: 800, marginTop: 100, marginBottom: 50 }}>

                <Typography align="center" style={{ marginBottom: 50, fontSize: '2rem', fontFamily: 'Work Sans' }} variant="h1">
                    <strong>Sobre CNP</strong>
                    <Divider />
                </Typography>

                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    A CNP é o acrónimo de Carteira Nacional Profissional (Digital).
                    Uma Plataforma que aloja as Ordens, cria uma base de dados legítima
                    que é gerida remotamente pelas Ordens, e os membros têm acesso a nível global.
                </Typography>
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    A Plataforma CNP com aplicativos móveis integrados, foi criado para
                    atender às necessidades das Ordens que buscam soluções de gerenciamento mais
                    avançadas dos membros inscritos que procuram maior aproximação com a classe da
                    qual pertencem, e do Público em geral que ganha a possibilidade de certificar-se
                    da legitimidade dos Profissionais que procuram de forma cómoda e segura.
                </Typography>
                <br />
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    A Plataforma CNP oferece 3 módulos:
                </Typography>
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    <strong>CNP Gestor:</strong> Permite criar uma base de dados digital segura, inscrever, catalogar os membros, gestão remota,
                    gestão de pagamento de quotas e permite o recrutamento por parte das empresas.
                </Typography>
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    <strong>CNP Carteira Digital:</strong> Permite aos membros, controlo de informações profissionais, ser recrutado dentro da Plataforma CNP,
                    ser notificado sobre vagas de emprego, receber comunicados, fazer pagamento das suas quotas, atendimento directo com a Ordem ou
                    Organizações via Chat, acesso a lista de membros da sua Ordem e pesquisa de membros nas outras Ordens;
                </Typography>
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    <strong>CNP Busca: </strong>
                    <ul>
                        <li>Permite ao Público fazer consulta (pesquisa) de profissionais legítimos nas Ordens.</li>
                    </ul>
                    <ul>
                        <li>Para as Empresas e Entidades Públicas e Privadas, o aplicativo CNP Busca permite acesso e
                            consulta de curriculum e recrutamento dos profissionais com apoio das Ordens e Anunciar
                            diretamente para os profissionais da Ordem.
                        </li>
                    </ul>
                </Typography>
                <br />
                <Typography style={{ fontSize: '1.3rem', fontFamily: 'Work Sans' }} variant="subtitle2">
                    A Plataforma CNP está disponível imediatamente para as Ordens e Organizações interessadas em melhorar os
                    processos de gestão e legitimidade dos seus membros inscritos. A plataforma é altamente personalizável e
                    pode ser adaptada para atender às necessidades específicas de cada Ordem e Organizações.
                </Typography>
            </Container>
            <Footer />
        </>
    )
}
