import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@mui/material';

const data = [1, 2, 3, 4];

function Media(props) {
  const { loading = false } = props;

  return (
    <Grid container justifyContent='center'>
      {(loading ? Array.from(new Array()) : data).map((index) => (
        <Box key={index} width={210} style={{margin: 20 }}>
          <Skeleton variant="rect" width={210} height={118} />
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      ))}
    </Grid>
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};

export default function YouTube() {
  return (
    <Box overflow="hidden">
      <Media loading />
      <Media />
    </Box>
  );
}
