import React, { useEffect, useState } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import MessageError from "../components/messages/messageError"
import { CardActionArea, CardContent, CardMedia } from "@mui/material"
import api from "../services/api"


const useStyles = makeStyles((theme) => ({
    cards: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 30,
    },
    root: {
        width: 230,
        margin: 10,
        border: '1px solid #a62929',
        borderRadius: 8,
        transition: '.4s',
        '&:hover': { background: '#b1b7ea', },
        [theme.breakpoints.down('xs')]: {
            width: '75%',
            margin: 5,
        },
    },
}))

export default function ActivarOdem() {
    const classes = useStyles()
    const [ordens, setOrdens] = useState([])
    const [messageError, setMessageError] = useState('')

    useEffect(() => {
        api.get('ordems')
            .then(res => {
                // console.log(res)
                setOrdens(res.data.ordens);
            }).catch(err => {
                // console.log(err)
                if (err.message === "Network Error") {
                    setMessageError(err.message)
                }
            })
    }, [])

    return (
        <div >
            {messageError && <MessageError message={messageError} />}

            <Typography align="center" style={{ color: 'red', fontSize: 25, fontFamily: 'Work Sans', marginTop: 20, fontWeight: 'bold' }} variant="h1">
                ACTIVAR ORDEM
            </Typography>

            <div className={classes.cards}>
                {ordens.map((ordem) => (
                    ordem.status === 'Activo' ?

                        <div key={ordem._id} className={classes.root} title={ordem.nome} >

                            {/* <a href={ordem.URL} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}> */}
                                <CardActionArea >
                                    <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                                        <CardMedia
                                            style={{ width: '100%', borderRadius: 8 }}
                                            component="img"
                                            alt="LogoOrdem"
                                            // height="150"
                                            //image={logo}
                                            image={ordem.logoURL}
                                            title={ordem.nome}
                                        />
                                    </div>
                                    <CardContent style={{ padding: 10 }}>
                                        <Typography color="textPrimary" noWrap variant="subtitle1" >
                                            {ordem.nome} <br />
                                        </Typography>

                                        <Typography color="textSecondary" gutterBottom variant="body1" >
                                            Localização: {ordem.local}
                                        </Typography>

                                        <Typography style={{ marginTop: 10 }} variant="body2" color="textSecondary">
                                            Descrição:{ordem.descricao}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            {/* </a> */}
                        </div>

                        :

                        <div key={ordem._id} className={classes.root} style={{ background: '#fff', opacity: .2, }}>

                            <CardActionArea >
                                <div style={{ display: 'flex', justifyContent: 'center', padding: 10, }}>
                                    <CardMedia
                                        style={{ width: '100%', borderRadius: 8 }}
                                        component="img"
                                        alt="LogoOrdem"
                                        // height="150"
                                        image={ordem.logoURL}
                                    />
                                </div>
                                <CardContent style={{ padding: 10 }}>
                                    <Typography color="textPrimary" noWrap variant="subtitle1" >
                                        {ordem.nome} <br />
                                    </Typography>

                                    <Typography color="textSecondary" gutterBottom variant="body1" >
                                        Localização: {ordem.local}
                                    </Typography>

                                    <Typography style={{ marginTop: 10 }} variant="body2" color="textSecondary">
                                        Descrição:{ordem.descricao}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </div>
                ))}
            </div>

        </div>
    )
}