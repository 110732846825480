import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import StatusMembros from "../status/StatusMembros";
import CloseIcon from '@mui/icons-material/Close';
import Perfil from '../utils/perfil';
import { DialogTitle } from '@mui/material';
import ListaBusca from './ListaBusca';

function SimpleDialog(props) {
    const { onClose, open } = props;
    const [index, setIndex] = React.useState(0)
    const [getOfLista, setgetOfLista] = React.useState(false)
    const handleClose = () => { onClose('') };

    return (
        <Dialog fullWidth open={open} scroll='body' >
            {props.pesquisa &&
                <DialogTitle mb={2}>
                    {
                        getOfLista ?
                            <IconButton color='error' title="fechar" style={{ position: 'absolute', right: 5, fontWeight: 'bold' }} onClick={() => setgetOfLista(false)}> <CloseIcon /></IconButton>
                            :
                            <IconButton color='error' title="fechar" style={{ position: 'absolute', right: 5, fontWeight: 'bold' }} onClick={handleClose}> <CloseIcon /></IconButton>
                    }
                </DialogTitle>
            }

            {/* CASO O RESULTADO DA PEQUISA TIVER MAIS DE UM RESULTADO, EXIBIR A LISTABUSCA E CASO 
            FOR APENAS UM RESULTADO VAI EXIBIR DIRECTAMENTE O RESULTADO */}
            {props.pesquisa ?
                <>
                    {(props.pesquisa.length > 1 && !getOfLista) ?
                        <ListaBusca
                            membros={props.pesquisa}
                            setIndex={setIndex}
                            setgetOfLista={setgetOfLista}
                        />
                        :
                        <>
                            <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', padding: 10 }}>
                                {/* <img style={{ borderRadius: 5 }} src={props.pesquisa[index]?.dadosPessoais?.fotoURL.includes('default-user.png') ? '/img/semFoto.jpeg' : props.pesquisa[index]?.dadosPessoais?.fotoURL} alt="" /> */}
                                <img style={{ borderRadius: 5 }} src={props.pesquisa[index]?.dadosPessoais?.fotoURL} alt="" />
                            </div>

                            <Typography style={{ fontFamily: 'Work Sans', lineHeight: 1, margin: 5, fontSize: 25, fontWeight: 'bold' }} align="center" variant="h1">
                                {props.pesquisa[index]?.dadosProfissionais?.nomeProfissional ?? props.pesquisa[index]?.nome}
                            </Typography>

                            <Typography style={{ fontFamily: 'Work Sans', lineHeight: 1.5, margin: 10 }} align="center" variant="body1">

                                {/* VERIFICANDO SE A ORDEM SELECIONADO É A DOS ADVOGADOS E APLICANDO MODO EXIBICAO ESPECIFICO */}
                                {(props.ordem._id === '63ab278e65d41e8e1cfa9e7a' && props.pesquisa[index]?.dadosPessoais?.perfil === 'Estagiário(a)') &&
                                    <>
                                        {props.pesquisa[index]?.dadosPessoais?.genero === 'M'
                                                ? 'Advogado '
                                                : 'Advogada '
                                        }
                                    </>
                                }
                                
                                <Perfil perfil={props.pesquisa[index]?.dadosPessoais?.perfil} genero={props.pesquisa[index]?.dadosPessoais?.genero} />
                                <br />

                                {' Carteira nº ' + props.pesquisa[index]?.numeroCarteira}

                                {props.pesquisa[index]?.advogado?.especialidade &&
                                    <>
                                        <br />
                                        <strong>Especialidade: </strong>{props.pesquisa[index]?.advogado?.especialidade}
                                    </>
                                }
                                <br />
                                <strong> {props.ordem.nome}</strong>
                            </Typography>
                            <StatusMembros status={props.pesquisa[index]?.status} />
                        </>
                    }
                </>
                :
                <div style={{ padding: 20 }}>
                    <Typography style={{ fontFamily: 'Work Sans', lineHeight: 1, }} align="center" variant="subtitle1">
                        Não encontramos nenhum membro correspondente a
                        <strong> {props.valor} </strong> na  <strong> {props.ordem.nome} </strong>
                    </Typography>

                    <Button style={{ marginTop: 20 }} size='small' fullWidth color='error' onClick={handleClose}>Fechar</Button>
                </div>
            }
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function ViewBuscaModal(props) {
    const [open, setOpen] = React.useState(props.open);
    const handleClose = () => { setOpen(false) };

    // console.log(props.pesquisa)

    return (
        <div>
            <SimpleDialog
                open={open}
                onClose={handleClose}
                pesquisa={props.pesquisa}
                pesquisaError={props.pesquisaError}
                ordem={props.ordem}
                valor={props.valor}
            />
        </div>
    );
}
