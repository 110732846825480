import * as React from 'react';
import { useState } from 'react';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@material-ui/core';
import { Box, IconButton, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MessageError from '../messages/messageError';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        // border: '1px solid #a62929',
        borderRadius: 8,
        margin: 10,
    },
}))

export default function Pesquisa(props) {
    const classes = useStyles();
    const [messageError, setMessageError] = useState('')
    const [url, setURL] = useState(false)

    const handleChange = (event) => {
        setURL(event.target.value.serverURL)
        props.setOrdem(event.target.value);
    };

    const search = async (e) => {
        e.preventDefault()
        setMessageError('')
        props.setPesquisa('')
        props.setPesquisaError('')
        props.setLoad(true)

        try {
            const response = await axios.post(`${url}membro/search?perPage=20`, { 'search': props.valor });
            props.setPesquisa(response.data.membros);
            props.setLoad(false)
            // console.log(response)

        } catch (error) {
            if (error.message === "Network Error") {
                setMessageError(error.message)
            }
            // console.log(error)
            props.setLoad(false)
            props.setPesquisa('');
            props.setPesquisaError(error.response.data.message);
        }
    }

    return (
        <>
            {messageError && <MessageError message={messageError} />}
            {/* <h1 class="animate__animated animate__bounce">An animated element</h1> */}
            <Typography className="animate__animated animate__bounce" align="center" style={{ marginTop: 15, fontSize: 25, fontFamily: 'Work Sans', fontWeight: 'bold' }} variant="h1">
                CNP Busca
            </Typography>

            {props.ordem ?
                <>
                    <Typography align="center" style={{ fontSize: 20, fontFamily: 'Work Sans', padding: 20 }} variant="h1">
                        <strong>
                            {
                                props.ordem.nome === 'Ordem dos Advogados de Angola' ?
                                    ' Ordem dos Advogados de Angola "Conselho Provincial de Luanda"'
                                    :
                                    props.ordem.nome
                            }
                        </strong>
                        <IconButton color='error' title="Remover seleção" style={{ marginLeft: 5, fontWeight: 'bold' }} onClick={() => { props.setOrdem(''); props.setPesquisa('') }}><CloseIcon /></IconButton>
                    </Typography>

                    <Box style={{ display: 'block', maxWidth: 560, marginLeft: 'auto', marginRight: 'auto' }} >
                        <form onSubmit={search} className={classes.root} style={{ border: '1px solid #a62929' }}>

                            <InputBase
                                required
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Digite o número da carteira ou nome"
                                onChange={(e) => props.setValor(e.target.value)}
                            />
                            <IconButton type="submiy" aria-label="search">
                                <img src={'/icones/services/pesquisarmembrosgeral.svg'} alt='' width={30} type="submiy" aria-label="search" />
                            </IconButton>
                        </form>
                    </Box>
                </>
                :
                <Box style={{ maxWidth: 560, marginLeft: 'auto', marginRight: 'auto', position: 'relative' }} >
                    <FormControl fullWidth>
                        <InputLabel style={{ padding: 5, paddingLeft: '2%' }} id="search">Selecionar Profissão</InputLabel>
                        <Select
                            className={classes.root}
                            size="small"
                            labelId="profissao"
                            id="profissao"
                            value={props.ordem}
                            onChange={handleChange}
                        >
                            {props.ordens.map((ordem) => (
                                (ordem.status === 'Activo' || ordem.status === 'Demo') &&
                                <MenuItem key={ordem._id} value={ordem}>{ordem.profissao}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    <IconButton type="submiy" aria-label="search" style={{ position: 'absolute', right: 11, top: 10 }}>
                        <img src={'/icones/services/busca3.svg'} alt='' width={30} type="submiy" aria-label="search" />
                    </IconButton>
                </Box>
            }
        </>
    );
}

