import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Container, Typography } from '@mui/material';
import GoBack from '../components/utils/GoBack';
import CentralCervices from './CentralServices';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        justifyItems: 'center',
    },

}))

export default function Template({ element: component, ...rest }) {
    const classes = useStyles();

    return (
        <Container >
            <GoBack />
            <Card style={{ display: 'flex', paddingTop: 10, paddingBottom: 10, }}>
                <Typography style={{ fontFamily: 'Work Sans', marginLeft: 'auto', marginRight: 'auto' }} noWrap className={classes.sessaoTitulo} variant='h5'>
                    <strong>CNP CENTRAL DE AÇÕES</strong>
                </Typography>
            </Card>

            {component ?? <CentralCervices />}

        </Container>
    );
}


