import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import Loading from "../utils/Loading"
import { Box, Container, Divider, Card } from "@mui/material"
import ServicosMenu from "./ServicosMenu"
import Notas from "../notas"
import AnuncioInformativoProfissoes from "../anuncios/AnuncioInformativoProfissoes"
import CardOrdem from "../layout/CardOrdem"
import CardContent from '@material-ui/core/CardContent';
import AnuncioOqueCNP from "../anuncios/AnuncioOqueCNP"
import AnuncioApp from "../anuncios/AnuncioApp"
// import aminateCss from "../style/animate.css"
// import aminateJs from "../scripts/animate.js"


const useStyles = makeStyles((theme) => ({

    cards: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 30,
    },

    root: {
        padding: 10,
        width: 300,
        margin: 20,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 5,
        },
    }
}))


export default function Body(props) {
    const classes = useStyles()

    return (
        <div >
            <Box className={classes.cards} >
                {/* <div data-anime="left"> */}
                <ServicosMenu
                    servicoNome={'Serviços "Tailor Made"'}
                    text={'A CNP tem como objectivo fornecer serviços de forma 100% customizado as necessidades do cliente.'}
                    // link={"/customizacao"}
                    icone={'/icones/services/servicos.svg'} />
                {/* </div> */}
                {/* <div data-anime="left"> */}

                <Card className={classes.root}>
                    <CardContent style={{ paddingBottom: 0 }}>
                        <img src={'/icones/services/CNPsobre.svg'} alt='' width={35} />
                    </CardContent>
                    <CardContent >
                        <Typography color="textPrimary" variant="subtitle1" style={{ lineHeight: 1.5, fontFamily: 'Work Sans', fontSize: 16 }}>
                            <strong>Sobre</strong>
                        </Typography>
                        <Divider />
                        <Typography color="textPrimary" variant="subtitle2" style={{ lineHeight: 1.5, fontFamily: 'Work Sans', fontSize: 14, marginTop: 10 }}>
                            A CNP é uma empresa de desenvolvimento de soluções.
                            Fundada em 2018, a empresa tem como objectivo fornecer soluções práticas e
                            inovadoras.
                        </Typography>

                    </CardContent>
                </Card>

                <ServicosMenu
                    data-anime="left"
                    servicoNome={'Anúncios'}
                    text={'Anuncie para vários profissionais, os seus produtos e serviços.'}
                    // link={"/sobre-anuncios"}
                    icone={'/icones/services/CNPLojas.svg'} />
                {/* </div> */}
            </Box>

            <AnuncioOqueCNP />

            <div  className="animate__backInLeft --animate-duration', '2s" id="ordens" style={{ textAlign: 'center' }}>
                <Typography style={{ color: 'red', fontSize: 32, fontFamily: 'Work Sans', marginTop: 30, fontWeight: 'bold' }} variant="h1">
                    ORDENS
                </Typography>
                <br />
            </div>

            <Container >
                <Divider />
                {props.ordens.length > 0 && <CardOrdem ordens={props.ordens} />}

                {!props.LoadingOrdens && <Loading />}
            </Container>

            <AnuncioInformativoProfissoes />
            <Notas />
            <AnuncioApp />
        </div>
    )
}