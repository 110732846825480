import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/system';
import Servicos from '../components/utils/servicos';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        justifyItems: 'center',
    },

}))

export default function CentralServices() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Servicos
                servicoNome={'Activar Ordem'}
                link={"/admin/activar-ordem"}
                icone={'/icones/menu/inscricao-estagiario.svg'} />

            <Servicos
                servicoNome={'Registrar Ordem'}
                link={"/admin/cadastro-ordem"}
                icone={'/icones/menu/inscricao-advogado.svg'} />

            <Servicos
                servicoNome={'Registrar Admin Ordem'}
                link={"/admin/cadastro-admin-ordem"}
                icone={'/icones/menu/inscricao-advogado.svg'} />

            <Servicos
                servicoNome={'Criar Anumcio'}
                link={"/admin/create-anuncio"}
                icone={'/'} />
        </Box>
    );
}


