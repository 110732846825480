import React from 'react';
import Template from '../index';
import CadastroAdminOrdem from '../ServiceCadastroAdminOrdem';


export default function PagCadastroAdminOrdem() {
    return (
        <Template element={<CadastroAdminOrdem />} />
    );
}
