import React, { useEffect, useState } from "react"
import { Typography } from "@material-ui/core"
import MessageError from "../components/messages/messageError"
import { Box, Grid } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Paper, TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import api from "../services/api"; 

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'end',
    },

    gridItem: {
        margin: 8,
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}))

export default function CadastroAdminOrdem() {
    const classes = useStyles()
    const [messageError, setMessageError] = useState('')
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [cadastroAdmin, setCadastroAdmin] = useState([]);
    const [ordens, setOrdens] = useState([]);

    useEffect(() => {
        api.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);
            }).catch(err => console.log(err))
    }, [setProvincias])


    useEffect(() => {
        api.get('ordens')
            .then(res => {
                setOrdens(res.data.ordens);
                // console.log(ordens)
            }).catch(err => {
                // console.log(err)
                if (err.message === "Network Error") {
                    setMessageError(err.message)
                }
            })
    }, [])

    function HandleChange(e) {

        e.target.files ?
            setCadastroAdmin({ ...cadastroAdmin, [e.target.name]: e.target.files[0] })
            :
            setCadastroAdmin({ ...cadastroAdmin, [e.target.name]: e.target.value })

        e.target.name === 'provinciaId' &&
            api.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                }).catch(err => console.log(err))
    }

    // useEffect(() => {
    //     api.post('/funcionario/create')
    //         .then(res => {
    //             setOrdens(res.data.ordens);
    //             // console.log(ordens)
    //         }).catch(err => {
    //             // console.log(err)
    //             if (err.message === "Network Error") {
    //                 setMessageError(err.message)
    //             }
    //         })
    // }, [])

    return (
        <div >
            {messageError && <MessageError message={messageError} />}

            <Typography align="center" style={{ color: 'red', fontSize: 25, fontFamily: 'Work Sans', marginTop: 20, fontWeight: 'bold' }} variant="h1">
                CADASTRO ADMIN ORDEM
            </Typography>

            <Box style={{ marginBottom: 30 }}>
                <Typography align="center" variant="subtitle1" style={{ margin: 10, fontFamily: 'Work Sans' }}>
                    DADOS PESSOAIS
                </Typography>

                <Grid container className={classes.root}>
                    <Grid xs={12} md={2} item className={classes.gridItem}>
                        <Paper >
                            <label for="foto" style={{ cursor: 'pointer', textAlign: 'center' }}>
                                {cadastroAdmin?.foto ?
                                    <>
                                        <Typography variant='body2'>
                                            <img src={URL.createObjectURL(cadastroAdmin.foto)} alt="Imagem" width="100%" title="alterar foto.." />
                                        </Typography>
                                    </>
                                    :
                                    <Typography variant='body2' style={{ padding: 8, paddingBottom: 30, paddingTop: 30 }}>
                                        <div style={{ display: "flex", justifyContent: 'center', margin: 20 }}>
                                            <Avatar />
                                        </div>
                                        Carregue uma <br />
                                        foto tipo passe
                                    </Typography>}
                                <input accept="image/png, image/jpg, image/jpeg" type="file" name="foto" id="foto" style={{ display: 'none' }} onChange={HandleChange} />
                            </label>
                        </Paper>
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Selecionar Ordem"
                            select
                            fullWidth
                            size="small"
                            name="conselhoId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.conselhoId || ''}
                        >
                            {ordens?.map((ordem) => (
                                <MenuItem key={ordem._id} value={ordem._id}>
                                    {ordem.nome}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="text"
                            required
                            label="Nome completo"
                            fullWidth
                            size="small"
                            name="nome"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.nome}
                        />
                    </Grid>

                </Grid>

                <Grid container className={classes.root}>
                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            fullWidth
                            required
                            label="Data de Nascimento"
                            type="date"
                            size="small"
                            name="dataNascimento"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.dataNascimento}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="text"
                            required
                            label="Portador do BI nº"
                            fullWidth
                            size="small"
                            name="bi"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.bi}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="date"
                            fullWidth
                            required
                            label="Data de emissão do B.I"
                            size="small"
                            name="dataEmissaoBi"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.dataEmissaoBi}
                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>
                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="text"
                            label="Nome do pai "
                            fullWidth
                            size="small"
                            name="nomePai"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.nomePai}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="text"
                            label="Nome da mãe"
                            fullWidth
                            size="small"
                            name="nomeMae"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.nomeMae}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Estado civil"
                            select
                            fullWidth
                            size="small"
                            name="estadoCivil"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.estadoCivil || ''}
                        >
                            <MenuItem key="s" value="Solteiro(a)">
                                Solteiro(a)
                            </MenuItem>
                            <MenuItem key="c" value="Casado(a)">
                                Casado(a)
                            </MenuItem>
                            <MenuItem key="d" value="Divorciado(a)">
                                Divorciado(a)
                            </MenuItem>
                            <MenuItem key="v" value=" Viúvo(a)">
                                Viúvo(a)
                            </MenuItem>
                        </TextField>
                    </Grid>

                </Grid>
                <Grid container className={classes.root}>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Provincia"
                            select
                            fullWidth
                            size="small"
                            name="provinciaId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.provinciaId || ''}
                        >
                            {provincias?.map((provincia) => (
                                <MenuItem key={provincia._id} value={provincia._id}>
                                    {provincia.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        {cadastroAdmin?.provinciaId ?
                            <TextField
                                required
                                type="text"
                                label="Municipio"
                                select
                                fullWidth
                                size="small"
                                name="municipioId"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={cadastroAdmin?.municipioId || ''}
                            >
                                {municipios?.map((municipio) => (
                                    <MenuItem key={municipio._id} value={municipio._id}>
                                        {municipio.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                            :
                            <TextField
                                type="text"
                                disabled
                                label="Municipio"
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                        }
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Morada (bairro, rua)"
                            fullWidth
                            size="small"
                            name="bairro"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.bairro}
                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="number"
                            label="Telefone 1"
                            fullWidth
                            size="small"
                            name="telefone1"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.telefone1}
                        />
                    </Grid>
                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="number"
                            label="Telefone 2"
                            fullWidth
                            size="small"
                            name="telefone2"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.telefone2}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Genero"
                            select
                            fullWidth
                            size="small"
                            name="genero"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.genero || ''}
                        >
                            <MenuItem key={'M'} value={'M'} name="genero">
                                Masculino
                            </MenuItem>
                            <MenuItem key={'F'} value={'F'} name="genero">
                                Feminino
                            </MenuItem>

                        </TextField>
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="text"
                            label="Nif"
                            fullWidth
                            size="small"
                            name="nif"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.nif}
                        />
                    </Grid>

                    <Grid item xs={12} md className={classes.gridItem}>
                        <TextField
                            required
                            type="email"
                            label="Email"
                            fullWidth
                            size="small"
                            name="email"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.email}
                        />
                    </Grid>

                    <Grid item xs={12} md className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Perfil"
                            select
                            fullWidth
                            size="small"
                            name="perfil"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={cadastroAdmin?.perfil || ''}
                        >
                            <MenuItem key={'angola'} value={'angola'} name="perfil">
                                ###
                            </MenuItem>

                        </TextField>
                    </Grid>
                </Grid>
            </Box >
        </div >
    )
}