import React from "react";
import Footer from "../components/layout/Footer";
import NavBar from "../components/layout/NavBar";
// import { useEffect } from "react";
import { Container, Typography } from "@mui/material";


export default function SobreAnuncios() {
    // const [pesquisa, setPesquisa] = useState('')

    // useEffect(() => {
    //         api.get('ordens')
    //             .then(res => {
    //                 setOrdens(res.data.ordens);
    //                 setLoadingOrdens(true)
    //                 // console.log(ordens)
    //             }).catch(err => {
    //                 // console.log(err)
    //                 if (err.message === "Network Error") {
    //                     setMessageError(err.message)
    //                 }
    //             })
    //     }, [])

    return (
        <>
            <NavBar />
            <Container style={{ minHeight: '90vh', marginTop: 100 }}>

                <Typography align="center" style={{ fontSize: '2rem', fontFamily: 'Work Sans' }} variant="h1">
                Sobre Anuncios
                </Typography>
            </Container>
            <Footer />
        </>
    )
}
