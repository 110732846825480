
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        paddingBottom: 30,
        // height: '50vh',
        background: 'url(/img/anuncios/anuncioOqueCNP.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            with: '100vw',
        },
    },

    conteudo: {
        // marginLeft: 20,
        marginLeft: '10%',
        marginRight: '40%',
        padding: 20,
        color: '#fff',
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            marginLeft: 0,
            // color: '#fff0f0',
        },
    }
}))

export default function AnuncioOqueCNP() {
    const classes = useStyles();

    return (
        <div id="sobrenos" className={classes.root}>
            <div className={classes.conteudo}>
                <Typography style={{ marginBottom: 20, fontSize: '2rem', fontFamily: 'Work Sans' }} variant="h1">
                    O que é  <span style={{ fontWeight: 'bold', fontSize: 40, marginRight: 3 }}>CNP</span>?
                </Typography>

                <Typography style={{ fontSize: '1.5rem', fontFamily: 'Work Sans', letterSpacing: 2, lineHeight: 1.3 }} variant="h1">
                    É o acrónimo de <span style={{ fontWeight: 'bold', fontSize: 25 }}>Carteira Nacional Profissional </span> Digital.
                    Uma Plataforma que aloja as Ordens, cria uma base de dados legítima
                    que é gerida remotamente pelas Ordens, e os membros têm acesso a nível global.
                    {/* <Button size="small" variant="outiline"  onClick={() => navigate('/sobre-cnp')}> Saber mais..</Button> */}
                </Typography>

            </div>
        </div>
    )
}