import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages";
import Login from "./pages/login";
import CadastroOrdem from './admin/ServiceCadastroOrdem'
// import CadastroAdminOrdem from './admin/ServiceCadastroAdminOrdem'
import TemplateAdmin from './admin'
import PagActivarOrdem from "./admin/pages/PagActivarOrdem";
import PagCadastroOrdem from "./admin/pages/PagCadastroOrdem";
import PagCadastroAdminOrdem from "./admin/pages/PagCadastroAdminOrdem";
import Sobre from "./pages/sobre";
import Acessibilidade from "./pages/acessibilidade";
import SobreAnuncios from "./pages/SobreAnuncios";
import TailorMade from "./pages/TailorMade";
import PoliticasServicos from "./pages/PoliticasServicos";
import CreateAnuncio from "./components/anuncios/createAnuncio";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route element={<Login />} path="/login" />
        <Route element={<Sobre />} path="/sobre-cnp" />
        <Route element={<Acessibilidade />} path="/acessibilidade" />
        <Route element={<SobreAnuncios />} path="/sobre-anuncios" />
        <Route element={<TailorMade />} path="/customizacao" />
        <Route element={<PoliticasServicos />} path="/politicas-e-servicos" />

        {/* CONSULTA DE MEMBROS PELO QRCODE */}
        <Route element={<Home />} path="/consulta-de-membro/:id" />

        <Route element={<TemplateAdmin />} path="/admin/dev"/>

        <Route element={<PagActivarOrdem />} path="/admin/activar-ordem" />
        <Route element={<PagCadastroOrdem />} path="/admin/cadastro-ordem" />
        <Route element={<PagCadastroAdminOrdem />} path="/admin/cadastro-admin-ordem" />

        {/* Anuncio */}
        <Route element={<TemplateAdmin element={<CreateAnuncio/>} />} path="/admin/create-anuncio" />

        <Route element={<CadastroOrdem />} path="/cadastroordem" />
        {/* <Route element={<CadastroAdminOrdem />} path="/cadastroadminordem" /> */}
        <Route path="*" element={<Home />} />
        {/* <Route path="*" element={<h1>nao encontrado</h1>} /> */}
      </Routes>
    </BrowserRouter>
  );
}
export default App;

