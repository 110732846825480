export default function Perfil(props) {

    const count = props.perfil?.length - 4
    const perfil = props.perfil?.substring(0, count)

    return (
        < span >
            {
                props.perfil === 'Presidente' ? 'Advogada' :
                    <>
                        {props.perfil === 'Transicao' ?
                            <>
                                {props.genero === 'M' && ' Estagiário'}
                                {props.genero === 'F' && ' Estagiária'}
                            </>
                            :
                            <>
                                {(props.perfil && props.genero === 'M') && perfil + 'o'}
                                {(props.perfil && props.genero === 'F') && perfil + 'a'}
                            </>
                        }
                    </>
            }
        </span >
    )
}