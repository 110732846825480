import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingBackdrop(props) {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
        >
            <CircularProgress thickness={5} />

            <p style={{ fontFamily: 'Work Sans', marginLeft: 10 }} >
                {
                    props.text ?
                        props.text
                        :
                        'Os dados estão sendo enviados... Por favor aguarda'
                }
            </p>
        </Backdrop>
    )
}

