import React, { useEffect, useState } from "react"
import { Divider, Typography } from "@material-ui/core"
import MessageError from "../messages/messageError";
import { Grid } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions, Paper, TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import api from "../../services/api";
import Anunciados from "./anunciados";
// import Loading from "../load/loading";
import LoadingBackdrop from "../load/LoadingBackdrop";


const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'end',
    },

    gridItem: {
        margin: 8,
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}))

export default function CreateAnuncio() {
    const classes = useStyles()
    const [messageError, setMessageError] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [emolumentos, setEmolumentos] = useState([]);
    const [anuncio, setAnuncio] = useState('');
    const [load, setLoad] = useState(false);


    useEffect(() => {
        api.get('/emolumentos')
            .then(res => {
                // console.log(res)
                setEmolumentos(res.data.emolumentos);
            }).catch(err => {
                if (err.message === "Network Error") {
                    setMessageError(err.message)
                }
                // console.log(err)
            })
    }, [])

    const createAnuncio = () => {
        setLoad(true)

        const formData = new FormData();
        formData.append('imagens', anuncio?.imagens ?? '');
        formData.append('anuncianteId', anuncio?.anuncianteId ?? '66bf3033e82a6ba01e4c4a12');
        // formData.append('anuncianteId', anuncio?.anuncianteId ?? '123ew');
        formData.append('emolumentoId', anuncio?.emolumentoId ?? '');
        formData.append('titulo', anuncio?.titulo ?? '');
        formData.append('tipo', anuncio?.tipo ?? '');
        formData.append('descricao', anuncio?.descricao ?? '');
        formData.append('link', anuncio?.link ?? '');


        api.post('/anuncio/create', formData)
            .then(res => {
                setLoad(false)
                console.log(res.data)
                setMessageSuccess(res.data.msg)
            }).catch(err => {
                setLoad(false)
                console.log(err)
            })
    }

    function HandleChange(e) {

        e.target.files ?
            setAnuncio({ ...anuncio, [e.target.name]: e.target.files[0] })
            :
            setAnuncio({ ...anuncio, [e.target.name]: e.target.value })
    }

    return (
        <div >
            <LoadingBackdrop open={load} text={'A salvar anuncio'} />
            {messageError && <MessageError message={messageError} />}

            <Typography align="center" style={{ color: 'red', fontSize: 25, fontFamily: 'Work Sans', marginTop: 20, fontWeight: 'bold' }} variant="h1">
                CRIAÇÃO DE ANUNCIO
            </Typography>

            <Grid container className={classes.root}>
                <Grid xs={12} md={2} item className={classes.gridItem}>
                    <Paper >
                        <label htmlFor="imagens" style={{ cursor: 'pointer', textAlign: 'center' }}>
                            {anuncio?.imagens ?
                                <>
                                    <Typography variant='body2'>
                                        <img src={URL.createObjectURL(anuncio.imagens)} alt="Imagem" width="100%" title="alterar imagens.." />
                                    </Typography>
                                </>
                                :
                                <Typography align="center" variant='body2' style={{ padding: 15 }}>
                                    Imagem
                                </Typography>}
                            <input accept="image/png, image/jpg, image/jpeg" type="file" name="imagens" id="imagens" style={{ display: 'none' }} onChange={HandleChange} />
                        </label>
                    </Paper>
                </Grid>

                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="text"
                        required
                        label="Titulo"
                        fullWidth
                        size="small"
                        name="titulo"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={anuncio?.titulo}
                    />
                </Grid>
                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="text"
                        required
                        label="Tipo"
                        fullWidth
                        size="small"
                        name="tipo"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={anuncio?.tipo}
                    />
                </Grid>
            </Grid>

            <Grid container className={classes.root}>
                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="text"
                        required
                        label="Descrição"
                        fullWidth
                        size="small"
                        name="descricao"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={anuncio?.descricao}
                    />
                </Grid>


                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="url"
                        required
                        label="Link"
                        fullWidth
                        size="small"
                        name="link"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={anuncio?.link}
                    />
                </Grid>


                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        required
                        type="text"
                        label="Emulumento"
                        select
                        fullWidth
                        size="small"
                        name="emolumentoId"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={''}
                    >
                        {emolumentos.map((emolumento) => (
                            <MenuItem key={emolumento._id} value={emolumento._id} >
                                <Typography style={{ fontFamily: 'Work Sans' }} variant="body1">{emolumento.designacao.toUpperCase()} - {emolumento.montante} </Typography>
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>

            <DialogActions>
                <Button disabled={load} variant="contained" color='secondary' style={{ background: '#85287e' }} onClick={createAnuncio}>
                    Salvar
                </Button>
            </DialogActions>


            <br />
            <br />
            <Divider />
            <Anunciados refresh={messageSuccess} />
        </div>
    )
}