
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 230,
        margin: 15,
        border: '1px solid #a62929',
        borderRadius: 8,
        transition: '.4s',
        '&:hover': { background: '#b1b7ea', },
        [theme.breakpoints.down('xs')]: {
            width: '75%',
            margin: 5,
        },
    },
    cards: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },

}))

export default function CardOrdem({ ordens }) {
    const classes = useStyles();
    // const image = new Image();
   

    return (
        <div className={classes.cards}>
            {ordens.map((ordem) => (
                ordem.status === 'Activo' ?

                    <div key={ordem._id} className={classes.root} title={ordem.nome} >
                    {/* { image.src = ordem.logoURL} */}

                        <a href={ordem.URL} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <CardActionArea >
                                <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                                    <CardMedia
                                        style={{ width: '100%', borderRadius: 8 }}
                                        component="img"
                                        alt="LogoOrdem"
                                        // height="150"
                                        //image={logo}
                                        image={ordem.logoURL}
                                        title={ordem.nome}
                                    />
                                </div>
                                
                                <CardContent style={{ padding: 10 }}>
                                    <Typography color="textPrimary" noWrap variant="subtitle1" >
                                        {
                                            ordem.nome === 'Ordem dos Advogados de Angola' ?
                                                'Conselho Provincial de Luanda'
                                                :
                                                ordem.nome
                                        } <br />
                                    </Typography>

                                    <Typography color="textSecondary" gutterBottom variant="body1" >
                                        <strong> Endereço: </strong>
                                        {ordem.local}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </a>
                    </div>

                    :

                    <div key={ordem._id} className={classes.root} style={{ background: '#fff', opacity: .2 }}>

                        <a href={ordem.URL} target={'_blank'} rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <CardActionArea >
                                <div style={{ display: 'flex', justifyContent: 'center', padding: 10, }}>
                                    <CardMedia
                                        style={{ width: '100%', borderRadius: 8 }}
                                        component="img"
                                        alt="LogoOrdem"
                                        // height="150"
                                        image={ordem.logoURL}
                                    />
                                </div>
                                <CardContent style={{ padding: 10 }}>
                                    <Typography color="textPrimary" noWrap variant="subtitle1" >
                                        {
                                            ordem.nome === 'Ordem dos Advogados de Angola' ?
                                                'Conselho Provincial de Luanda'
                                                :
                                                ordem.nome
                                        } <br />
                                    </Typography>

                                    <Typography color="textSecondary" gutterBottom variant="body1" >
                                        <strong> Endereço: </strong> {ordem.local}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </a>
                    </div>
            ))}
        </div>
    );
}
