import React from "react";
import { Instagram, LinkedIn, YouTube } from "@material-ui/icons";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    menuItens: {
        marginRight: 10,
        marginLeft: 10,
        textDecoration: 'none',
        color: '#d72026',
        '&:hover': {
            color: '#fea12a',
        },
    },
})

export default function RedesSocias() {
    const classes = useStyles()

    return (
        <nav>
            <a href={'https://www.facebook.com/profile.php?id=100093203286250'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                <FacebookRoundedIcon />
            </a>

            <a href={'https://www.instagram.com/cnp.angola/'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                <Instagram />
            </a>

            <a href={'https://www.linkedin.com/company/cnp-carteira-nacional-profissional/'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                <LinkedIn />
            </a>

            <a href={'https://www.youtube.com/@cnp.angola'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                <YouTube />
            </a>
        </nav>
    )
}