import * as React from 'react';
import { useState } from 'react';
import MessageError from '../messages/messageError';
import ofaAPI from '../../services/ofaAPI';
import { useParams } from 'react-router-dom';

export default function ConsultorPorQrCode(props) {
    const { id } = useParams()
    const [messageError, setMessageError] = useState('')
    // const [url, setURL] = useState('')

    React.useEffect(() => {

        if (id) {
            setMessageError('')
            props.setPesquisa('')
            props.setPesquisaError('')
            props.setLoad(true)

            ofaAPI.get(`membro/${id}`)
                .then(response => {
                    // console.log(response)
                    props.setPesquisa([response.data.membro]);
                    props.setLoad(false)
                }).catch(error => {
                    // console.log(error)
                    if (error.message === "Network Error") {
                        setMessageError(error.message)
                    }
                    props.setLoad(false)
                    props.setPesquisa('');
                    props.setPesquisaError(error.response.data.message);
                })
        }

    }, [id])

    return (messageError && <MessageError message={messageError} />);
}

