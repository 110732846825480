import React from "react";
import { Grid, } from "@material-ui/core"
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import RedesSocias from "./redesSocias";
import { Divider, Typography } from "@mui/material";
// import InstallBanner from "./install";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        // marginTop: 30,
        background: '#e2eaf6',
        color: '#12141b',
        paddingTop: 30,
        paddingBottom: 20,
    },

    img: {
        maxHeight: 50,
        width: 130,
        margin: 10,
        [theme.breakpoints.down('xs')]: {
            // display: 'none'
            opacity: 0
        },
    },
    DownloadCNPApp: {
        width: 120,
        margin: 3,
        borderRadius: 8
    },

    copyright: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'center',
    },
    gridItens: {
        padding: 10,
    },

    top: {
        borderRadius: '100%',
        padding: 10,
        background: '#e2eaf6',
        position: 'absolute',
        top: -25,
        right: 50,
    },

    links: {
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            color: '#fea12a',
        },
    },
}))

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {' ©  CNP - '}
            { new Date().getFullYear() }
            {'  Todos os direitos reservados.'}
        </Typography>
    );
}
export default function Footer() {
    const classes = useStyles()

    return (
        <footer id="contactos" className={classes.root}>
            <div className={classes.top}>
                <a href="#top">
                    <img src="icones/CNPHome2.svg" alt="home" width={40} />
                </a>
            </div>

            <Grid container justifyContent="center">
                {/* <InstallBanner /> */}
                <Grid xs md={3} item className={classes.gridItens} style={{ position: 'relative' }}>
                    <img src="img/logoCNP.png" alt="cnp" className={classes.img} />
                    <br />
                    <div style={{ position: 'absolute', bottom: 12 }}>
                        <Typography noWrap style={{ fontFamily: 'Work Sans', margin: 5, fontSize: 14 }} variant="subtitle1">
                            Download CNP App
                        </Typography>

                        <a href={'https://apps.apple.com/ao/app/cnp-carteira-digital/id6480178712'} target={'_blank'} rel="noreferrer" style={{ marginRight: 10 }}>
                            <img alt="APP story" src="/img/AppStore.png" className={classes.DownloadCNPApp} />
                        </a>
                        <a href={'https://play.google.com/store/apps/details?id=com.cnpdigital.cnpao'} target={'_blank'} rel="noreferrer" style={{ marginRight: 10 }}>
                            <img alt="PLAY story" src="/img/Androidapp.png" className={classes.DownloadCNPApp} />
                        </a>
                    </div>
                </Grid>

                <Grid xs md={2} item className={classes.gridItens}>
                    <Typography color="textPrimary" variant="subtitle1" style={{ lineHeight: 1.5, fontFamily: 'Work Sans', fontSize: 14, marginBottom: 10, marginTop: 10 }}>
                        <strong>Quem Somos </strong>
                        <br />
                        <Link to={'/politicas-e-servicos'} className={classes.links}> Regras, politicas e serviços</Link>
                        <br />
                        <Link to={'/acessibilidade'} className={classes.links}> Acessibilidade</Link>
                        <br />
                        {/* <Link to={''} className={classes.links}>Mapa do site</Link> */}
                    </Typography>
                </Grid>

                <Grid xs md={2} item className={classes.gridItens}>
                    <Typography color="textPrimary" variant="subtitle1" style={{ lineHeight: 1.5, fontFamily: 'Work Sans', fontSize: 14, marginBottom: 10, marginTop: 10 }}>
                        <strong>Contactos </strong>
                        <br />
                        <ul>
                            <li> geral@cnp.ao</li>
                            <li>suport@cnp.ao </li>
                        </ul>

                    </Typography>
                    <Divider />
                    <Typography color="textPrimary" variant="subtitle1" style={{ lineHeight: 1.5, fontFamily: 'Work Sans', fontSize: 14, }}>
                        Rua 03, casa n 258,
                        <br />
                        Bairro Nova Vida
                        <br />
                        Luanda - Angola
                    </Typography>
                </Grid>

                <Grid xs md={2} item className={classes.gridItens}>
                    <Typography noWrap color="textPrimary" variant="subtitle1" style={{ lineHeight: 1.5, fontFamily: 'Work Sans', fontSize: 14, marginLeft: 20 }}>
                        <strong>Redes Sociais</strong>
                        <br />
                    </Typography>
                    <RedesSocias />
                </Grid>

            </Grid>

            <div className={classes.copyright}>
                    {/* &copy;  2018 - 2023 CNP Todos os direitos reservados. */}
                    <Copyright />
            </div>
        </footer>
    )
}