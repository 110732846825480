import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Card, Container, Divider, Grid, Typography } from "@mui/material";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 50,
        marginBottom: 50,
    },
}))

export default function Notas() {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            <Typography align="center" style={{ color: 'red', fontSize: 32, fontFamily: 'Work Sans', marginTop: 30, fontWeight: 'bold' }} variant="h1">
                NOTAS
            </Typography>
            <Divider />
            <br />

            <Grid container>
                <Grid xs={12} md item m={2} >
                    <Card style={{ padding: 20 }}>
                        <Typography style={{ lineHeight: 1.2, fontFamily: 'Work Sans', marginBottom: 10 }} variant="subtitle1">
                            "A plataforma CNP tornou tudo tão simples e transparente. Fico feliz,
                            é gratificante ver o quanto pode ajudar!"
                        </Typography>

                        <div style={{ fontFamily: 'Work Sans', display: 'flex', alignItems: 'center' }}>
                            <Avatar src="/img/avatar-notas/djamila.jpeg" style={{ marginRight: 5 }} />
                            <Typography noWrap variant="body1" style={{ lineHeight: 1 }}>
                                Djamila Antunes <br />
                                <small style={{ fontSize: 12 }}>
                                    Estudante Universitária
                                </small>
                            </Typography>
                        </div>
                    </Card>
                </Grid>

                <Grid xs={12} md item m={2}  >
                    <Card style={{ padding: 20 }}>
                        <Typography style={{ lineHeight: 1.2, fontFamily: 'Work Sans', marginBottom: 10 }} variant="subtitle1">
                            "A plataforma facilita a conexão entre membro e organizações, tornando a interação mais acessível e gratificante."
                        </Typography>

                        <div style={{ fontFamily: 'Work Sans', lineHeight: 1, display: 'flex', alignItems: 'center' }}>
                            <Avatar src="/img/avatar-notas/willson.jpeg" style={{ marginRight: 5 }} />
                            <Typography noWrap variant="body1" style={{ lineHeight: 1 }}>
                                Genivaldo José <br />
                                <small style={{ fontSize: 12 }}>
                                    Anónimo
                                </small>
                            </Typography>
                        </div>
                    </Card>
                </Grid>
            </Grid>

            <Grid container>
                <Grid xs={12} md item m={2} >
                    <Card style={{ padding: 20 }}>
                        <Typography style={{ lineHeight: 1.2, fontFamily: 'Work Sans', marginBottom: 10 }} variant="subtitle1">
                            "Buscar informações confiáveis sobre um profissional nunca foi tão fácil.
                            A plataforma CNP agora é meu recurso essencial para me manter esclarecida e prevenida."
                        </Typography>

                        <div style={{ fontFamily: 'Work Sans', display: 'flex', alignItems: 'center' }}>
                            <Avatar src="/img/avatar-notas/djamila2.jpeg" style={{ marginRight: 5 }} />
                            <Typography noWrap variant="body1" style={{ lineHeight: 1 }}>
                                Ana Mária<br />
                                <small style={{ fontSize: 12 }}>
                                    Dona de casa
                                </small>
                            </Typography>
                        </div>
                    </Card>
                </Grid>

                <Grid xs={12} md item m={2}  >
                    <Card style={{ padding: 20 }}>
                        <Typography style={{ lineHeight: 1.2, fontFamily: 'Work Sans', marginBottom: 10 }} variant="subtitle1">
                            "A CNP é uma ferramenta poderosa para impulsionar mudanças e defender a classe dos profissionais."
                        </Typography>
                        <br />
                        <div style={{ fontFamily: 'Work Sans', lineHeight: 1, display: 'flex', alignItems: 'center' }}>
                            <Avatar src="/img/avatar-notas/miguel.jpeg" style={{ marginRight: 5 }} />
                            <Typography noWrap variant="body1" style={{ lineHeight: 1 }}>
                                Junilson Miguel <br />
                                <small style={{ fontSize: 12 }}>
                                    Anónimo
                                </small>
                            </Typography>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}