
import { Typography } from "@mui/material";
import React from "react";

export default function StatusMembros({ status }) {

    return (
        < >
            {status === 'Activo' &&
                <Typography style={{ padding: 10, background: '#7ebf42', color: '#fff', fontSize: 30, fontFamily: 'Work Sans', lineHeight: 1.5 }} align="center" variant="body1">
                    {status}
                </Typography>
            }
            {/* {status === 'Inactivo' &&
                <Typography style={{padding: 10, background: 'grey', color: '#fff', fontSize: 30, fontFamily: 'Work Sans', lineHeight: 1.5 }} align="center" variant="body1">
                    {status}
                </Typography >
            } */}
            {/* {status === 'Suspenso' && */}
            {status === 'Inactivo' &&
                <Typography style={{ padding: 10, background: 'red', color: '#fff', fontSize: 30, fontFamily: 'Work Sans', lineHeight: 1.5 }} align="center" variant="body1">
                    {status}
                </Typography >
            }
        </>
    )
}