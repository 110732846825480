
import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import { Card, Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        width: 300,
        margin: 20,
        // cursor: "pointer",
        // minHeight: 300,
        // width: '15%',
        // minWidth: 180,
        // margin: 10,
        // border: '1px solid #a62929',
        // transition: '.5s',
        // '&:hover': {
        //     background: '#b1b7ea',
        //     border: '1px solid #a62929',
        // },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: 5,
            // padding: 0,
        },
    }
}))

export default function ServicosMenu({ icone, servicoNome, link, text }) {
    const classes = useStyles()

    return (
        <Card title="Saber mais.." className={classes.root}>
            {/* <CardActionArea > */}
            {/* <Link style={{ textDecoration: 'none' }} to={link}> */}
            <CardContent style={{ paddingBottom: 0 }}>
                <img src={icone} alt={servicoNome} width={50} />
            </CardContent>
            <CardContent >
                <Typography color="textPrimary" variant="subtitle1" style={{ lineHeight: 1.5, fontFamily: 'Work Sans', fontSize: 16 }}>
                    <strong>    {servicoNome}</strong>
                </Typography>
                <Divider />
                <Typography color="textPrimary" variant="subtitle2" style={{ lineHeight: 1.5, fontFamily: 'Work Sans', fontSize: 14, marginTop: 10 }}>
                    {text}
                </Typography>
            </CardContent>
            {/* </Link> */}
            {/* </CardActionArea> */}
        </Card>
    )
}